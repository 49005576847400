.hello {
  font-family: "Acme", sans-serif;
}

.App-skull2 {
  /* animation: App-skull2-spin infinite 20s linear; */
  height: 15vmin;
  position: relative;
  /* animation-duration: 30s;
  animation-delay: 2s */
}

.hello-page {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@media only screen and (max-width: 600px) {
  p.hello {
    width: 75%;
  }
}
