#navlist li {
  display: inline-block;
  list-style-type: none;
  padding-right: 20px;
}

li {
  font-size: x-large;
}

.connected-page {
  background-color: #179ffb;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  padding-left: 2%;
}

a {
  color: inherit;
}

@media only screen and (max-width: 600px) {
  li {
    font-size: small;
  };
}
