.App {
  text-align: center;
}

/* .App-skull{
  animation: App-skull-spin infinite 20s linear;
  height: 15vmin;
  position: relative;
  animation-duration: 10s;
} */
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

div.sticky {
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 20;
  padding: 50px 0 0 50px;
  min-height: 100vh;
  min-width: 15vw;
  font-size: 20px;
  /* margin-left: 30px; */
  z-index: 1;
  align-items: left;
  /* justify-content: unset; */
}
/* div.container6 p {
  margin: 0 } */

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  background-color: #3498db;
}

.recent-work {
  background-color: #2bacb5;
}

.stay-connected {
  background-color: #ffef35;
  color: black;
}

.skills {
  background-color: #e74c3c;
}

.who-am-i {
  background-color: #44c650;
}

.wrapper {
  position: relative;
}

.left {
  /* width: 10%; */
  position: absolute;
  float: left;
}
/* 
.right {
  width: 90%;
  right: 0;
  position: absolute;
} */

.transparent {
  /* background:#7f7f7f; */
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 600px) {
  .sticky {
    width: 75%;
  }
  .hello {
    font-size: inherit;
  }
}

@keyframes App-skull-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
  0% {
    left: 0vw;
    top: 0vh;
  }
  25% {
    left: -25vw;
    top: 0vh;
  }
  50% {
    left: -25vw;
    top: -25vh;
  }
  75% {
    left: 0vw;
    top: -25vh;
  }
  100% {
    left: 0vw;
    top: 0vh;
  }
}
@keyframes App-skull2-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  0% {
    left: -25vh;
    top: 0vh;
  }
  25% {
    left: 25vh;
    top: 0vh;
  }
  50% {
    left: 25vh;
    top: 300vh;
  }
  75% {
    left: -25vh;
    top: 300vh;
  }
  100% {
    left: -25vh;
    top: 0vh;
  }
}
